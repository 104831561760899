@import url('https://fonts.googleapis.com/css?family=Athiti');
@import url('https://fonts.googleapis.com/css?family=Boogaloo');

$primary: #b85084; /* MAIN COLOR */
$secondary: #285067; /* SECONDARY COLOR */
$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Athiti', sans-serif;
  color: $gray;
}
h1, h2, h3, h4 {
  font-family: 'Boogaloo', cursive;

}
hr.style11 {
	height: 6px;
	background: url(http://ibrahimjabbari.com/english/images/hr-11.png) repeat-x 0 0;
    border: 0;
}
.navbar {
  margin-bottom: 0;
}
.navbar-nav {
    margin: 0 -15px;}
.navbar, .navbar-header{
  height: 100px;
  position: relative;

  .navbar-nav>li>a {
    margin-top: 25px;
    text-transform: uppercase;
    font-size: 1.2em;
    border:1px solid $wht;
    &:hover{
      background-color: rgba(8,61,79,.8);
      color: $wht;
      border-radius: 0;
      border:2px dashed $wht;
      @media(max-width:767px){
        background-color: $wht;
        color: $blk;
        border-radius: 0;
        border:0px solid $wht;
      }
    }
    background: $wht;
    @media (max-width:767px){
      line-height: 20px;
      margin-top: 0;
    }
  }
  @media (max-width: 767px){
    height: 50px;
    .logo {
      max-height: 50px;
    }
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.hero-info{
  padding: 50px 0px;
  p {
    font-size: 1.3em;
    padding: 0 20px;
  }
}
.hero-quote {
    padding: 60px 0;
    @media(max-width: 767px){
      padding:40px 0;
    }
    background: $primary;
  h3 {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 2em;
    line-height:1.6em;
    color: $wht;
    @media(max-width:767px){
      font-size: 1.5em;
    }
  }
}
.hero-process {
  padding: 60px 0;
  background-color:lighten($wht, 2%);
  color: $blk;
  @media(max-width: 767px){
    padding: 20px 0;
  }
  p {
    font-size: 1.35em;
    // margin: 100px 0;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }

  }
}
.hero-contact {
  background-color:darken($secondary, 10%);
  padding: 0;
  color: $wht;
  // .col-md-12{
  //   background-color: $blk;
  //   padding: 100px 50px;
  //   color: $wht;
  //   @media(max-width: 767px){
  //     padding:40px 20px;
  //   }
  //
  // }
  .col-md-12 {
    padding: 20px 50px;
    @media(max-width: 767px){
      padding:40px 20px;
      color: lighten($wht, 20%);
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 26px;
    }
    a {
      text-decoration: none;
      color: lighten($wht, 20%);
      font-weight: 100;
    }

    p {
      font-size:1.2em;
      line-height:1.5em;
      color: lighten($wht, 20%);
    }
    .footer-banner {
      ul {
        padding-left: 0;
      }
    }
  }
}
footer {
  text-align: center;
  padding-top: 25px;
  background: $secondary;
  color: lighten($wht, 20%);
}
